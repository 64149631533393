import React from 'react'
import "../../components/Common/Style/spinner.css";

const Spinner = () => {
  return (
    <div className="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}

export default Spinner