// Tenenv
export const tenenvKey = "tenenv"
export const loginsPerSpKey = "logins_per_sp"
export const loginsPerIdpKey = "logins_per_idp"
export const loginsCountByKey = "logins_countby"
export const loginsPerCountryKey = "logins_per_country"
export const loginsGroupByDayKey = "logins_groupby/day"
export const registeredUsersCountByKey = "registered_users_countby"
export const registeredUsersByCountryKey = "registered_users_country"
export const spsKey = "sps"
export const idpsKey = "idps"
export const communitiesKey = "communities"
export const countryStatsByVoKey = "country_stats_by_vo"
export const communitiesGroupByKey = "communities_groupby"
export const communityMembersByStatusKey = "members_bystatus"
export const registeredUsersPerCountryGroupByKey = "registered_users_country_group_by"
export const registeredUsersGroupByKey = "registered_users_groupby"